import React from "react"

import Layout from "../basic"
import SEO from '../../components/seo'
import SearchGrid from 'components/searchgrid'

const NewsReportAll = ({data, pageContext}) => {
	let searchCTA = '';

	const filterOptions = [];
	let allData = data.allPressRelease.edges;
	let page = {};
	if (data.allContentPage.edges) {
		page = data.allContentPage.edges[0].node;
	}
	return (
		<>
		<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
			<SearchGrid apiController="press/release" title="" description="" searchCTA="Search" initialData={allData} />
		</Layout>
		<SEO title="News Reports" description="" />
		</>
	)
}

export default NewsReportAll;