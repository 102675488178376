import React from "react";
import { graphql } from 'gatsby';

import NewsReportAll from "../templates/NewsReportAll";

const NewsReport = ({ data, pageContext }) => <NewsReportAll data={data} />

export default NewsReport;

export const query = graphql`
	query {
		allPressRelease(sort: {fields: showDate, order: DESC}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					excerpt
					headline
					body
					photo
					permalink
					showDate
					uri
					_model
				}
			}
		}
	  allContentPage(filter: {uri: {eq: "/news/"}, lang: {eq: "en"}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			headerImage
			link
			linkText
			danosFoundation
			danosFooterTab
	      }
	    }
	  }
	}
`